const symbolsToSuperscript = [
  '&reg;',
  '&#174;',
  '®',
  '&trade;',
  '&#8482;',
  '™',
  '&#8480;',
  '℠'
];

export function addSuperscripts(issuerStatement: string) {
  return symbolsToSuperscript.reduce((acc, symbol) => {
    return acc?.replace(new RegExp(symbol, 'g'), (s) => {
      return `<sup>${s}</sup>`;
    });
  }, issuerStatement);
}
